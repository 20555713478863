import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const mdx = data.mdx // data.mdx holds your post data
  const frontmatter = mdx.frontmatter
  const body = mdx.body
  return (
    <Layout>
        <SEO title={frontmatter.title} />
        <h1 id="top">{frontmatter.title}</h1>
        <div>
            <MDXRenderer>{body}</MDXRenderer>
        </div>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
      fields {
        priority
        slug
        category
      }
    }
  }
`